import { Gender } from "@/enums/gender.enum";
import { TagLifestyle } from "@/enums/tag-lifestyle.enum";
import { TagInterest } from "@/enums/tag-interest.enum";
import { TagPersonality } from "@/enums/tag-personality.enum";
import { TagFood } from "@/enums/tag-food.enum";
import { RoomTypeName } from "@/enums/room-type-name.enum";
import { OnboardingStayDuration } from "@/enums/onboarding-stay-duration.enum";
import { StayMoodPreference } from "@/enums/stay-mood-preference.enum";
import { StayGenderPreference } from "@/enums/stay-gender-preference.enum";

export default {
	"form": {
		"password": {
			"confirm-rule": "The value doesn't match the previous password input",
			"confirm-other-rules": "The value matches the other password input but the rules aren't respected",
			"confirm-placeholder": "Confirm password",
			"label": "Password",
			"placeholder-choose": "Choose a password",
			"placeholder-enter": "••••••••••••••",
			"confirm-validation-label": "Password confirmation",
		},
		"phone": {
			label: "Phone number",
			validation: "Phone number must be in the format +xxx xx xx xx xx",
		},
		"firstName": {
			label: "First name",
		},
		"lastName": {
			label: "Last name",
		},
		"agreement": {
			label: "I confirm that I have read, consent and agree to User Agreement and Privacy Policy",
		},
		"reset-token": {
			label: "Email code",
			placeholder: "Enter your code",
			validation: "Email code must be a 6-digit number.",
		},
		"birthdate": {
			label: "Birthdate",
		},
		"gender": {
			label: "Gender",
			values: {
				[Gender.FEMALE]: "Female",
				[Gender.MALE]: "Male",
				[Gender.OTHER]: "Other",
			},
		},
		"2fa-token": {
			"label": "Two-factor authentication code",
			"placeholder": "Enter the 6 digits code or backup code",
			"placeholder-token": "424242",
			"validation-message-matches":
				"Token must be either a 6 digits code, or a 20 characters long backup code with both digits and alphabetic values",
		},
		"generic-required": "This field is required",
		"generic-length": "Please provide a value between {min} and {max} characters",
		"generic-email": "Please provide a valid email",
	},
	"partial": {
		"credit-card": {
			"change": "Change card",
			"card-number": "Card number",
			"date": "Date",
			"cvc": "CVC",
		},
		"option": {
			"subscribe": "Subscribe",
			"unsubscribe": "Unsubscribe",
			"monthly-total": "Monthly Total",
			"total-explanation": "Billed every first of the month",
			"endDate": "You can benefit from it until the",
		},
		"package": {
			"price": "€/month",
			"tagline": "And all of Flatmates services",
			"img-alt": "Picture of the inside of a room",
		},
		"service": {
			"opening-hours": "Opening hours",
		},
		"user": {
			"add-roommate": "Add a new roommate",
			"no-roommate": "No roommates",
		},
		"navigation": {
			public: {
				"signin": "Login",
				"signup": "Apply now",
				"logout": "Logout",
				"logo-alt":
					"Link to the home page containing all the informations about STATION F's co-living extension",
			},
			app: {
				"balance": "Balance",
				"api-tokens": "API tokens",
				"assessments": "Assessments",
				"badge-requests": "Badge requests",
				"billing": "Billing",
				"cookies": "Cookie Settings",
				"dashboard": "Dashboard",
				"delivery-address": "Delivery address",
				"documents": "Documents",
				"events": "Events",
				"flats": "Flats",
				"gcu": "GCU",
				"help-center": "Help center",
				"invoices": "Invoices",
				"legal-mentions": "Legal Mentions",
				"my-plan": "My plan",
				"onboarding-sessions": "Onboarding",
				"parking-spaces": "Parking",
				"privacy-policy": "Privacy policy",
				"rooms": "Rooms",
				"settings": "Settings",
				"sources": "Sources",
				"spaces": "Spaces",
				"statistics": "Statistics",
				"stays": "Stays",
				"switch-account": "Switch account",
				"towers": "Towers",
				"warnings": "Warnings",
			},
		},
		"header": {
			logout: "Logout",
		},
	},
	"ui": {
		"multi-select": {
			"no-options-text": "Nothing to see here...",
			"placeholder": "Select some values",
		},
		"single-select": {
			"no-options-text": "Nothing to see here...",
			"placeholder": "Select some values",
		},
	},
	"service": {
		"infinite-scroll": {
			"no-results": {
				title: "Sorry! No results found...",
				text: "We should have put some random text here, but decided no 🙃",
			},
			"error": {
				title: "Error while fetching, try reloading the page.",
				text: "We should have put some random text here, but decided no 🙃",
				button: "Try again",
			},
		},
		"popup": {
			"close-button": "Close",
		},
		"searchbar": {
			"placeholder": "Search",
			"no-results": "No results",
		},
		"table": {
			"no-results-text": "No results found",
		},
	},
	"helpers": {
		"password-rules": {
			length: "Min. 12 characters, max 64 characters",
			oneLowercase: "Min. one lowercase letter",
			oneUppercase: "Min. one uppercase letter",
			oneNumber: "Min. one number",
			oneSpecialChar: "Min. one special character",
		},
		"http": {
			"403": "You do not have access to the requested resource.",
			"404": "Not Found",
			"409": "There seems to be a conflict",
		},
	},
	"pages": {
		"404": {
			title: "Something's missing",
			description:
				"The thing you’re looking for is unfortunately not here…\nIf you think something’s wrong, please contact our support team at flatmates@stationf.co.",
			button: "Go back",
		},
		"api-tokens": {
			defaults: {
				title: "API tokens",
			},
			list: {
				"deletion-warning": "You are about to permanently delete an API token. Do you confirm the deletion?",
			},
			table: {
				"no-api": "No APIs found",
			},
		},
		"assessments": {
			defaults: {
				title: "Assessments",
				furnitures: "Furnitures",
				surfaces: "Surfaces",
				photos: "Photos",
			},
			create: {
				"confirm": "You are about to create an assessment. Do you confirm the creation?",
				"already-exists": "An assessment already exists for this stay.",
				"button": "Generate assessment",
				"reason": {
					in: "Selected assessment type is IN and resident status is neither LIVE or ARRIVING",
					out: "Selected assessment type is OUT and resident status is neither LEAVING, ENDED or WAITING_REFUND",
					secondary: "Selected resident is a secondary",
				},
			},
			details: {
				"no-result-message-furnitures": "Please fill assessment to access furnitures conditions.",
				"no-result-message-surfaces": "Please fill assessment to access surfaces conditions.",
				"no-address": "Address not found. Please contact staff.",
			},
			fill: {
				"no-furniture": "No furnitures found. Please try again later or reach support.",
				"no-surface": "No surfaces found. Please try again later or reach support.",
				"no-assessment": "No assessments found",
			},
		},
		"badge-requests": {
			defaults: {
				title: "Badge requests",
			},
			details: {
				"received": "Set as received",
				"verified": "Set as verified",
				"printed": "Set as printed",
				"cancelation-warning": "You are about to cancel this badge request. Do you confirm?",
				"cancelation-refund-warning": "Note that resident will not be refunded.",
				"validation-popup": "You are about to valide this badge request. Please enter card ID",
				"card": "View card",
			},
			table: {
				"no-badge": "No badge requests found",
			},
		},
		"billing": {
			actions: { download: "Download", pay: "Pay" },
			title: "Billing",
			summary: {
				invoice: {
					"amount": "Amount",
					"dueDate": "Due date",
					"state": "State",
					"no-invoice": "No invoices yet, come back later",
				},
			},
			paymentMethod: {
				update: {
					title: "Update payment method",
				},
			},
			invoices: {
				"title": "Invoices",
				"toggler": {
					new: "Stripe",
					legacy: "Old",
				},
				"type-filter": {
					label: "Type",
					placeholder: "Filter by type",
				},
				"table": {
					"headers": {
						serial: "serial",
						user: "user",
						date: "date",
						amount: "amount",
						state: "state",
						creditNotes: "credit notes",
						download: "download",
						type: "type",
						actions: "actions",
					},
					"no-invoice": "No invoices found",
				},
			},
			creditNotes: {
				title: "Credit notes for invoice",
				table: {
					"headers": {
						serial: "serial",
						date: "date",
						reason: "reason",
						amount: "amount",
						download: "download",
					},
					"no-credit": "No credit notes found",
				},
			},
		},
		"auth": {
			"signin": {
				"title": "Log-in",
				"forgot-password": "Forgot password",
				"backup-token": "Backup token",
				"submit": "Login",
				"credentials-mismatch": "The credentials you specified don't match.",
				"token-invalid": "The token you have provided is invalid",
			},
			"forgot-password": {
				title: "Reset password",
				submit: "Send",
				explain:
					"We just sent you a link to reset your password! Can't find it? Please check your spams and contact us if you don't have it.",
			},
			"reset-password": {
				"title": "New password",
				"submit": "Reset password",
				"success": "Your password has been reset successfully. You will be redirected to sign-in page.",
				"invalid-token": "The code you provided does not match or is expired.",
				"password-mismatch": "Your passwords don't match.",
			},
			"select-stay": {
				"title": "Choose a Flatmates account",
				"logged-as": "You're logged as",
				"contract-start": "Start of contract",
				"contract-end": "End of contract",
				"unknown": "unknown",
				"create-stay": "Create a new stay",
				"validation-title": "Are you sure?",
				"confirm-new-stay":
					"You’re about to request the creation of a new stay. This means that you will apply to join Flatmates again.",
			},
			"signup": {
				title: "Sign up with Email",
				submit: "Next",
				login: "Log in",
				sourceMissing: "Only partners can apply to flatmates.",
			},
		},
		"dashboard": {
			"events-title": "Upcoming events",
			"invoice": {
				"title": "Latest invoice",
				"view-all": "View all",
				"information": "Information",
				"due-date": "Due date",
				"price": "Price",
				"total": "Total",
				"no-invoice": "No invoices yet",
				"insurance": "Multi-risk insurance contribution",
			},
			"flat-title": "My flat",
			"close-button": "Close",
			"register-button": "Register",
			"event-full": "The event is full",
			"location": "Location",
			"organizer": "Organizer",
			"bookings": "Remaining seats",
			"unregister-button": "Unregister",
			"roommates-modal-title": "Add a roommate",
			"roommates-modal-description": "Your roommate will receive an invitation to join Flatmates.",
			"roommates-modal-button": "Send",
			"roommates-email-placeholder": "Email address of your guest",
			"roommates-email-label": "Email",
			"roommates-firstName-label": "First name",
			"roommates-firstName-placeholder": "First name of your guest",
			"roommates-lastName-placeholder": "Last name of your guest",
			"roommates-lastName-label": "Last name",
			"roommates-submit-error": "Please contact staff for further informations.",
		},
		"delivery-address": {
			"title": "Delivery address",
			"subtitle": "Here is your address to use",
			"copy": "Copy to clipboard",
			"additional-infos":
				"Packages are not received in your absence, so you must be present to receive them safely.",
		},
		"documents": {
			"defaults": {
				title: "Documents",
			},
			"errors": "Error while downloading, it seems there is no document",
			"tooltip-message-forbidden": "You don't have access to this document",
			"tooltip-message-empty": "Empty document",
		},
		"flats": {
			defaults: {
				title: "Flats",
			},
			list: {
				"deletion-warning": "You are about to permanently delete a flat. Do you confirm the deletion?",
			},
			table: {
				"no-flat": "No flats found",
			},
		},
		"help-center": {
			"title": "Help Center",
			"create-ticket": "Create ticket",
			"sections": {
				title: "Sections",
			},
			"tickets": {
				list: {
					"open-tickets": "Open Tickets",
					"closed-tickets": "Closed Tickets",
					"no-tickets": "No tickets",
					"tickets-in-creation": "Ticket(s) in creation",
					"viewed": "This ticket has been viewed",
				},
				create: {
					title: "Create new ticket",
					category: "Select your category",
					details: "Please give us some details",
					created: "Ticket is being created, it will be visible in a few minutes.",
					solved: "Ticket has been solved successfully.",
				},
				details: {
					"title": "Ticket",
					"reply": "Write a reply",
					"send": "Send",
					"comment-sent": "Comment has been sent successfully.",
					"solve": "Solve",
					"solved": "Solved",
					"ticket-solved": "Ticket has been solved successfully.",
				},
			},
		},
		"invoices": {
			defaults: { title: "Invoices" },
			export: {
				title: "Billing Export",
				payments: "Payment",
				sales: "Sale",
				success: "Successfully downloaded file",
			},
			payment: { success: "The invoice was successfully paid" },
		},
		"onboarding-sessions": {
			defaults: {
				title: "Onboarding",
			},
			list: {
				"deletion-warning":
					"You are about to permanently delete an onboarding session. Do you confirm the deletion?",
			},
			table: {
				"no-session": "No onboarding sessions found",
			},
		},
		"parking-spaces": {
			defaults: {
				title: "Parking",
			},
			list: {
				"deletion-warning": "You are about to permanently delete a parking space. Do you confirm the deletion?",
			},
			table: {
				"no-space": "No parking spaces found",
			},
		},
		"rooms": {
			defaults: {
				title: "Rooms",
			},
			list: {
				"deletion-warning": "You are about to permanently delete a room. Do you confirm the deletion?",
			},
			details: {
				"input-help": "Generate new codes to see it appear here",
			},
			actions: {
				"generate-new-codes": {
					"update": "Change resident’s codes",
					"temporary": "Generate temporary codes",
					"state-occupied": "This will impact the current resident!",
					"state-free": "This won't impact any resident because the room isn't currently occupied.",
					"description":
						"You are about to generate new codes, erasing the current ones.\nThis action is irreversible. Do you confirm?",
					"operation-succeeded-occupied":
						"Operation succeeded! Residents have just received an email with their new codes",
					"operation-succeeded-free": "Operation succeeded!",
				},
			},
			table: {
				"no-room": "No rooms found",
			},
		},
		"settings": {
			title: "Settings",
			sections: {
				"profile": {
					"title": "Profile",
					"success": "Profile changes have been saved successfully.",
					"labels": {
						firstName: "First name",
						lastName: "Last name",
						email: "Email",
						phone: "Phone number",
					},
					"actions": {
						"edit-picture": "Edit picture",
						"save-changes": "Save changes",
					},
					"edit-picture": {
						"title": "Profile Picture",
						"update": "Update your profile picture",
						"details": "Change your profile picture here",
						"upload": "Upload an image",
						"delete": "Delete picture",
						"upload-success": "Picture has been successfully uploaded.",
						"delete-success": "Picture has been successfully deleted.",
					},
				},
				"password": {
					title: "Password",
					success: "Password has been changed successfully.",
					labels: {
						"password": "New password",
						"confirm": "Confirm password",
						"validation-confirm": "Password confirmation",
					},
					placeholders: {
						password: "Enter your new password",
						confirm: "Confirm your new password",
					},
					actions: {
						"save-changes": "Save password",
					},
				},
				"two-factor": {
					"title": "Two-factor authentication",
					"status": {
						enabled: "Enabled",
						disabled: "Disabled",
					},
					"instructions": [
						"1. Scan this QR Code with an authenticator app such as Google Authenticator.",
						"2. Enter a valid token generated with the app to confirm the setup.",
					],
					"backup-codes": {
						title: "Backup codes",
						description:
							"Here are your backup codes in case you do not have access to your authenticating device. They are single use each and cannot be recovered later, store them somewhere safe.",
					},
					"actions": {
						enable: "Enable two-factor authentication",
						disable: "Disable two-factor authentication",
					},
					"new-code": {
						title: "Generate new backup codes",
					},
				},
				"badge": {
					"title": "Badge",
					"new-request": {
						"reason-label": "What happened?",
						"select-label": "My badge is",
						"reason-placeholder": "Choose a reason",
						"success": "Badge request has been sent successfully.",
						"payment-information": "You will be billed {price} for you new badge.",
						"badge-information": "Your old badge will be deactivated.",
						"error": "An error occured with your badge request.",
					},
					"ongoing-request": {
						information: "You initiated a badge request because your badge was {reason} on {date}",
					},
					"cancel": {
						"title": "Cancel Badge Request",
						"confirmation-description": "Are you sure you want to cancel your current badge request?",
						"refund-information": "You will not be reimbursed for any renewal fees incurred.",
						"success": "Badge request has been canceled successfully.",
					},
					"actions": {
						"request-new-badge": "Request New Badge",
						"cancel-badge-request": "Cancel Badge Request",
						"retry-payment": "Pay now",
						"go-to-hal": "Go to HAL",
					},
					"status": {
						awaiting_verification: "Awaiting Verification",
						awaiting_payment: "Awaiting Payment",
						awaiting_print: "Awaiting Print",
						awaiting_pickup: "Awaiting Pickup",
						received: "Received",
						canceled: "Canceled",
					},
					"billing": "billing page",
					"no-payment-method": "You don't have any payment method, you can upload it in the",
					"processing-payment": "Processing payment",
					"is-stationf": "As a STATION F resident please use HAL to request a new badge.",
				},
				"wifi": {
					title: "WiFi",
					actions: {
						"reset-password": "Reset password",
					},
					placeholders: {
						login: "Your login",
					},
					messages: {
						success: "Your WiFi password has been changed. You will receive an email in few seconds.",
					},
				},
			},
		},
		"sources": {
			defaults: {
				title: "Sources",
			},
			list: {
				"deletion-warning": "You are about to permanently delete a source. Do you confirm the deletion?",
			},
			table: {
				"no-source": "No sources found",
				"link": { description: "Copy link", success: "Copied to clipboard !" },
			},
		},
		"spaces": {
			"title": "Spaces & services",
			"opening-hours": "Opening hours",
			"about-title": "About {name}",
			"more-info": "More info",
			"opening-information": "Opening information",
			"website": "Website",
			"mail": "Mail",
			"perks": "Perks",
			"available-perks": "Available perks",
			"view-offer": "View the offer",
		},

		"statistics": {
			title: "Statistics",
			data: {
				"onboarded": "Onboarded",
				"onboarded-description": "Residents onboarded in the selected month",
				"outboarded": "Outboarded",
				"outboarded-description": "Residents outboarded in the selected month",
				"applicants": "Applicants",
				"applicants-description": "Residents who applied in the selected month",
				"highest": "Highest",
				"highest-description": "Residents peak in the selected month",
				"standard": {
					available: "Available standard room",
					unavailable: "Unavailable standard room",
					occupied: "Occupied standard room",
					revenue: "standard room revenue",
				},
				"premium": {
					available: "Available premium room",
					unavailable: "Unavailable premium room",
					occupied: "Occupied premium room",
					revenue: "premium room revenue",
				},
				"couple": {
					available: "Available couple room",
					unavailable: "Unavailable couple room",
					occupied: "Occupied couple room",
					revenue: "couple room revenue",
				},
				"insurance": "Insurance",
				"cleaning": "Extra cleaning",
				"parking": "Parking",
				"badge": "Badge",
				"feesExt": "Fees (ext)",
				"fees": "Fees",
				"other": "Other",
			},
			form: { date: { placeholder: "Select a date" } },
			errors: {
				global: "Error while fetching global stats, try reloading the page.",
				yearly: "Error while fetching yearly stats, try reloading the page.",
			},
		},
		"stays": {
			defaults: {
				title: "Stays",
			},
			export: {
				"error": "Error while exporting, try reloading the page.",
				"matching-report": "Match applicants",
			},
			details: {
				sections: {
					"info": "Info",
					"sources": "Sources",
					"ac": "Access control",
					"stay": "Stay",
					"tags": "Tags",
					"room": "Room",
					"options": "Options",
					"mates-wishes": "Mates Wishes",
					"flat": "Flat residents",
					"warnings": "Warnings",
					"card": "Card",
				},

				billing: {
					paid: "Bank balance up to date",
					unpaid: "Unpaid bank balance",
				},

				actions: {
					"approve": {
						title: "Approve application",
						label: "Accept",
						descr: "You are about to approve application. Do you confirm?",
						source: "I certify that I have verified the existence of the user on adm",
					},
					"refuse": {
						title: "Refuse application",
						label: "Refuse",
						descr: "You are about to refuse application. This action is irreversible. Do you confirm?",
					},
					"delayed": {
						title: "Waiting list",
						label: "Add to waiting list",
						descr: "You are about to add application to the waiting list. Do you confirm?",
					},
					"assign-room": {
						label: "Assign room",
						descr: "You are about to assign room. Do you confirm?",
					},
					"update-date": {
						label: "Update arrival date",
						descr: "You are about to update arrival date. Do you confirm?",
					},
					"set-as-live": {
						label: "Set as Live",
						descr: "You are about to set this resident as Live. Please enter its badge ID to continue.",
					},
					"outboard": {
						label: "Outboard",
						descr: "You are about to outboard this stay. This action is irreversible. Do you confirm?",
					},
					"change-room": {
						label: "Change room",
						descr: "You are about to change room. Do you confirm?",
					},
					"change-parking": {
						label: "Change parking",
						descr: "You are about to change parking. Do you confirm?",
					},
					"cancel-outboarding": {
						label: "Cancel outboarding",
						descr: "You are about to cancel this stay outboarding. Do you confirm?",
					},

					"refund": {
						"descr": "You are about to refund this stay's deposit. Do you confirm?",
						"label": "Refund deposit",
						"retrieve-error": "Sorry, the amount of the deposit couldn't be fetched.",
					},
					"log-as-user": {
						title: "Log as user",
						descr: "You are about to log in as the current user. You'll have to log out to get back to your account. Do your confirm?",
						success: "Operation succeeded! Refresh the page to be logged as {name}",
					},
					"edit-user": {
						title: "Edit user information",
						label: "Edit user",
					},
					"promo-code": {
						title: "Coupon",
						invalid: "Promotion code is invalid.",
						deletion:
							"You are about to delete stripe promotion code of the user {user}. Do you confirm the deletion?",
					},
					"invoices": {
						title: "Invoices",
					},
				},
			},
			table: {
				"no-stay": "No stays found",
			},
		},
		"towers": {
			defaults: {
				title: "Towers",
			},
			list: {
				"deletion-warning": "You are about to permanently delete a tower. Do you confirm the deletion?",
			},
			table: {
				"no-tower": "No towers found",
			},
		},
		"events": {
			defaults: {
				title: "Events",
			},
			table: {
				"no-event": "No events found",
			},
		},
		"my-plan": {
			"plan-title": "My plan",
			"package-details": {
				"title": "Package details",
				"total": "Monthly total",
				"billed-monthly": "Billed every first of the month",
				"insurance": "Multi-risk insurance contribution",
			},
			"options-title": "My options",
			"journey-flatmates": {
				"room-code-button": "Get my room code",
				"leave-button": "Leave flatmates",
				"title": "Journey at Flatmates",
				"start-date": "Start date",
				"departure-date": "Departure date",
				"tooltip": { "departure-date": "You already have a departure date" },
				"room-modal": {
					"title": "Room code",
					"description":
						"This code will let you access your room. It’s strictly personal and should not be shared with anyone.",
					"code-not-working": "The code doesn't work?",
					"generate-codes": "Generate a new one.",
				},
				"leave-modal": {
					"title": "Leave Flatmates",
					"description":
						"We’re sad to see you leave :/ If you’re here “just to try”, please note that as soon you will click send your departure will be validated.\nPlease note that if you are in a couple studio, your roommate will be outboarded at the same time.",
					"have-relative": "Note that your relative will be impacted too.",
					"date": {
						label: "Select your departure date",
						placeholder: "Select a departure date",
						help: "Please select a date conforming to the priori notice of 7 days",
					},
					"reason-type": {
						label: "Why are you leaving?",
						placeholder: "Choose a reason",
					},
					"reason-details": {
						label: "We would love to know more on your experience at Flatmates",
						placeholder: "Give us more details",
					},
				},
			},
			"my-options": {
				"subscribe-modal": {
					title: "Subscribe",
					submit: "Subscribe",
					cancel: "Cancel",
					message:
						"You're about to subscribe to the {optionName} option. You will be billed for it every first day of the month.",
				},
				"unsubscribe-modal": {
					title: "Unsubscribe",
					submit: "Unsubscribe",
					cancel: "Cancel",
					message:
						"Are you sure you want to unsubscribe from the {optionName} option? You will still be able to benefit from it until the end of the month.",
				},
			},
		},
		"warnings": {
			defaults: {
				title: "Warnings",
			},
			create: {
				confirm:
					"You are about to create a warning. This action is irreversible, a warning can not be deleted. Do you confirm the creation?",
			},
			table: {
				"no-warning": "No warnings found",
			},
		},
		"landing": {
			"section-one": {
				title: {
					join: "Join",
				},
				description: "Flatmates is the first entrepreneur-only coliving space in the world.",
				subtitle: "STATION F's coliving space",
			},
			"utils": {
				apply: "Apply now",
			},
			"section-two": {
				"title": "Say hello to",
				"description":
					"Flatmates is designed for STATION F entrepreneurs who want to live the full experience: live together! Affordable and flexible common housing, located inside a beautiful building nearby STATION F.",
				"book-visit-button": "Book a visit",
			},
			"section-three": {
				"title": "What's included",
				"info-button": "More info",
			},
			"section-four": {
				"img-alt": "Inside of a Flatmates' bedroom",
				"package-tagline": "And all of Flatmates services mentioned above",
			},
			"section-five": {
				title: "Where is",
				address:
					"Flatmates is located 1 rue Jean-Jacques Rousseau, Ivry-sur-Seine. That’s only 10-min biking from STATION F Campus",
			},
			"footer-links": {
				"gcu": "GCU",
				"privacy-policy": "Privacy Policy",
				"legal-mentions": "Legal Mentions",
				"stationf": "stationf.co",
				"cookies": "Cookies",
			},
		},
		"onboarding": {
			steps: {
				application: "Application",
				review: "In review",
				package: "Package",
				mates: "Desired flatmates",
				preferences: "Preferences",
				contract: "Contract",
				payment: "Payment",
				thanks: "Thank you",
			},
			application: {
				"title": "Apply to Flatmates",
				"join-reason-label": "Why would you like to join FLATMATES?",
				"join-reason-placeholder": "It is great",
				"community-value-placeholder": "A lot",
				"community-value-label": "What can you bring to the community?",
				"roomTypes": {
					label: "Which package would you want?",
					[RoomTypeName.STANDARD]: "Standard {amount}/month",
					[RoomTypeName.PREMIUM]: "Premium {amount}/month",
					[RoomTypeName.COUPLE]: "Couple {amount}/month",
				},
				"stayDurations": {
					label: "How long do you wish to stay at Flatmates?",
					placeholder: "Please select a duration",
					[OnboardingStayDuration.LESS_THAN_A_MONTH]: "Less than a month",
					[OnboardingStayDuration.BETWEEN_ONE_AND_TWO_MONTHS]: "Between 1 and 2 months",
					[OnboardingStayDuration.BETWEEN_TWO_AND_FOUR_MONTHS]: "Between 2 and 4 months",
					[OnboardingStayDuration.BETWEEN_FOUR_AND_SIX_MONTHS]: "Between 4 and 6 months",
					[OnboardingStayDuration.MORE_THAN_SIX_MONTHS]: "More than 6 months",
				},
				"source": "I certify that I'm at STATION F",
				"apply": "Apply",
			},
			review: {
				title: "Your application is being reviewed",
				text: "Yay… your application is currently under review by our team! They will contact you as soon as possible to tell you more on the next steps!",
			},
			package: {
				"title": "Your Package",
				"arrival-date": {
					label: "Arrival date",
					placeholder: "Select a date",
				},
				"selection-title": "Your package selection",
				"bottom-text": "Want a different package? Please contact",
				"selected-session-not-found": "Your selected session could not be found.",
				"confirm-modal": {
					title: "Are you sure?",
					content:
						"Note that you will need to complete your Flatmates onboarding process 8 days before the date you selected to move-in.",
				},
			},
			mates: {
				"title": "Desired Flatmates",
				"mate-input": {
					label: "email of the friends you want to share a flat with (the one associated to their Flatmates account)",
					placeholder: "email{'@'}example.com",
				},
				"add-wish": "Add wish",
				"duplicate-wish-error": "Sorry, you cannot add the same wish twice.",
				"own-wish-error": "Sorry, you cannot add your own email.",
			},
			preferences: {
				"title": "Preferences",
				"lifestyle-title": "Lifestyle",
				"mood-preference": {
					label: "I would rather live in an apartment which is:",
					[StayMoodPreference.FULL_OF_LIFE]: "Full of life",
					[StayMoodPreference.CALM]: "Calm",
					[StayMoodPreference.BALANCED]: "Balanced",
				},
				"gender-preference": {
					label: "Do you prefer to live with people of a specific gender?",
					[StayGenderPreference.SAME]: "Same gender",
					[StayGenderPreference.MIXED]: "Mixed",
					[StayGenderPreference.DONT_CARE]: "I don't care",
				},
				"tags-label": "Please select the tags which describe you",
				"tags-minimum": "(minimum {amount})",
				"tags-previous": "Previous",
				"tags-next": "Next",
				"lifestyle": {
					label: "Lifestyle",
					tags: {
						[TagLifestyle.ADVENTURER]: "Adventurer",
						[TagLifestyle.ALWAYS_OUT]: "Always Out",
						[TagLifestyle.APÉRO_AFTERWORK]: "Apéro/Afterwork",
						[TagLifestyle.BUSY_BEE]: "Busy Bee",
						[TagLifestyle.COUCH_POTATO]: "Couch Potato",
						[TagLifestyle.EARLY_BIRD]: "Early bird",
						[TagLifestyle.ECOLOGIST]: "Ecologist",
						[TagLifestyle.ENTREPRENEUR]: "Entrepreneur",
						[TagLifestyle.FREELANCER]: "Freelancer",
						[TagLifestyle.HOMEBODY]: "Homebody",
						[TagLifestyle.MESSY]: "Messy",
						[TagLifestyle.MR_CLEAN]: "Mr/Mrs Clean",
						[TagLifestyle.NETFLIX_CHILL]: "Netflix & Chill",
						[TagLifestyle.NIGHT_OWL]: "Night Owl",
						[TagLifestyle.ORGANIZED]: "Organized",
						[TagLifestyle.PARTY_ANIMAL]: "Party Animal",
						[TagLifestyle.PARTY_THROWER]: "Party Thrower",
						[TagLifestyle.WORKAHOLIC]: "Workaholic",
						[TagLifestyle.NON_SMOKER]: "Non smoker",
						[TagLifestyle.ALWAYS_LATE]: "Always late",
						[TagLifestyle.LIGHT_SLEEPER]: "Light Sleeper",
						[TagLifestyle.EVENING_SHOWER]: "Evening Shower",
						[TagLifestyle.PET_OWNER]: "Pet Owner",
						[TagLifestyle.PUNCTUAL]: "Punctual",
						[TagLifestyle.MORNING_SHOWER]: "Morning Shower",
						[TagLifestyle.GREEN_THUMB]: "Green Thumb",
						[TagLifestyle.MUSICIAN]: "Musician",
						[TagLifestyle.STUDENT]: "Student",
					},
				},
				"interest": {
					label: "Interests",
					tags: {
						[TagInterest.ART_LOVER]: "Art Lover",
						[TagInterest.BOARD_GAMES_MASTER]: "Board Games Master",
						[TagInterest.BOOK_WORM]: "Book Worm",
						[TagInterest.BUSINESS_MINDED]: "Business Minded",
						[TagInterest.DESIGN_OBSESSED]: "Design Obsessed",
						[TagInterest.FASHION_VICTIM]: "Fashion Victim",
						[TagInterest.FITNESS_FREAK_GYM_RAT]: "Fitness Freak / Gym Rat",
						[TagInterest.GAMER]: "Gamer",
						[TagInterest.GLOBE_TROTTER]: "Globe-Trotter",
						[TagInterest.MOVIE_BUFF]: "Movie Buff",
						[TagInterest.MUSIC_ADDICT]: "Music Addict",
						[TagInterest.PET_LOVER]: "Pet Lover",
						[TagInterest.POLITICS_NEWS_ADDICT]: "Politics/News Addict",
						[TagInterest.RUNNING_ADDICT]: "Running Addict",
						[TagInterest.SPORTS_FANATIC]: "Sports Fanatic",
						[TagInterest.TECH_SAVVY]: "Tech Savvy",
						[TagInterest.YOGA_LOVER]: "Yoga Lover",
						[TagInterest.CONFERENCES_STATIONF]: "Conferences {'@'} Station F",
						[TagInterest.FOOTBALL_SOCCER_PRO]: "Football (Soccer) Pro",
						[TagInterest.GOSSIP_LOVER]: "Gossip Lover",
						[TagInterest.COMPUTER_SCIENCE_JUNKIE]: "Computer Science Junkie",
						[TagInterest.DANCE_MACHINE]: "Dance Machine",
						[TagInterest.BASKETBALL_PRO]: "Basketball Pro",
						[TagInterest.NATURAL_SCIENCE_ENTHUSIAST]: "Natural Science Enthusiast",
						[TagInterest.TENNIS_PRO]: "Tennis Pro",
						[TagInterest.THEATRE_LOVER]: "Theatre Lover",
						[TagInterest.ENGINEERING_ENTHUSIAST]: "Engineering Enthusiast",
						[TagInterest.MARKETING_EXPERT]: "Marketing Expert",
					},
				},
				"personality": {
					label: "Personality",
					tags: {
						[TagPersonality.CHATTERBOX]: "Chatterbox",
						[TagPersonality.CLASS_CLOWN]: "Class Clown",
						[TagPersonality.DOWN_TO_EARTH]: "Down to Earth",
						[TagPersonality.DREAMER]: "Dreamer",
						[TagPersonality.EASY_GOING]: "Easy-Going",
						[TagPersonality.GEEK]: "Geek",
						[TagPersonality.GENEROUS]: "Generous",
						[TagPersonality.GRUMPY]: "Grumpy",
						[TagPersonality.INTROVERT]: "Introvert",
						[TagPersonality.LAZY]: "Lazy",
						[TagPersonality.LONER]: "Loner",
						[TagPersonality.MANIACAL]: "Maniacal",
						[TagPersonality.OUTGOING]: "Outgoing",
						[TagPersonality.PERFECTIONIST]: "Perfectionist",
						[TagPersonality.PROACTIVE]: "Proactive",
						[TagPersonality.CLUMSY]: "Clumsy",
						[TagPersonality.SOCIAL_BUTTERFLY]: "Social Butterfly",
						[TagPersonality.SPONTANEOUS]: "Spontaneous",
						[TagPersonality.STRONG_TEMPER]: "Strong temper",
						[TagPersonality.CREATIVE]: "Creative",
						[TagPersonality.THRIFTY]: "Thrifty",
						[TagPersonality.CHEERFUL]: "Cheerful",
						[TagPersonality.AMBITIOUS]: "Ambitious",
						[TagPersonality.PEACEFUL]: "Peaceful",
						[TagPersonality.FUN]: "Fun",
						[TagPersonality.ARTIST]: "Artist",
						[TagPersonality.SENSITIVE]: "Sensitive",
						[TagPersonality.CONFIDENT]: "Confident",
					},
				},
				"food": {
					label: "Food",
					tags: {
						[TagFood.AT_HOME_DINING]: "At Home Dining",
						[TagFood.BEER]: "Beer",
						[TagFood.BRUNCH]: "Brunch",
						[TagFood.CHEESE_LOVER]: "Cheese Lover",
						[TagFood.COCKTAIL_LOVER]: "Cocktail Lover",
						[TagFood.COOKING_CHEF]: "Cooking Chef",
						[TagFood.DELIVERY]: "Delivery",
						[TagFood.FOODIE]: "Foodie",
						[TagFood.HEALTHY_FOOD]: "Healthy Food",
						[TagFood.JUNK_FOOD_LOVER]: "Junk-food Lover",
						[TagFood.LA_FELICITA]: "La Felicita",
						[TagFood.MEAT_LOVER]: "Meat Lover",
						[TagFood.DONT_TOUCH_MY_FOOD]: "Don't touch my food",
						[TagFood.QUICK_MEALS]: "Quick Meals",
						[TagFood.FOOD_SHARER]: "Food sharer",
						[TagFood.VEGETARIAN]: "Vegetarian",
						[TagFood.WINE_TASTER]: "Wine taster",
						[TagFood.LONG_DINNERS]: "Long Dinners",
						[TagFood.VEGAN]: "Vegan",
					},
				},
			},
			contract: {
				"title": "Contract",
				"loading-message": "We're preparing your contract, this may take a few seconds...",
				"signature-failed": "An error occured with your contract signature",
				"signature-declined": "You refused to sign the contract",
				"signature-failed-message": "Please try again or contact us at",
				"signature-declined-message": "Please contact us at",
				"contract-signed": "You contract is signed",
			},
			payment: {
				"title": "Finalize your enrolment",
				"your-package": "Your package",
				"today-title": "Today",
				"booking-fees": "Booking fees",
				"deposit": "Deposit",
				"total-today": "Total today",
				"every-month-title": "Every month",
				"every-month-message": "Your rent will be debited every first day of the month.",
				"insurance": "Insurance",
				"month-total": "Total per month",
				"discount": "Discount ({percent}%)",
				"proceed-payment": "Proceed with payment",
				"promo-code": {
					card: "Promotion code",
					error: "This promotion code is invalid.",
					success: "Promotion code applied!",
				},
				"invoice-account-info": "Your invoices are available on your secured Flatmates account.",
				"steps": {
					card: "Payment card",
					fees: "Booking fees",
					deposit: "Deposit",
				},
				"card-holder-input": {
					label: "Card holder name",
					placeholder: "John Doe",
				},
				"card-input": {
					label: "Payment card",
				},
				"confirm-input": {
					label: "I accept that this card will be debited every month for the payment of the rent and the options subscribed",
				},
				"pay-now": "Pay {amount} now",
				"continue-payment": "Continue payment",
				"processing": {
					card: "Saving payment card",
					fees: "Processing booking fees payment",
					deposit: "Processing deposit payment",
				},
				"failed": {
					card: "Payment card saving failed",
					fees: "Booking fees payment failed",
					deposit: "Deposit payment failed",
				},
				"retry": {
					fees: "Retry booking fees payment",
					deposit: "Retry deposit payment",
				},
				"pay": {
					fees: "Pay booking fees",
					deposit: "Pay deposit",
				},
				"change-card": "Change card",
				"completed": "Payment completed",
				"finish": "Finish onboarding",
				"default-error": {
					loading: "An error occured loading the page, please try again or contact the support",
					card: "An error occured while saving your card, please try again.",
					fees: "An error occured whith your booking fees payment, please try again.",
					deposit: "An error occured whith your deposit payment, please try again.",
				},
			},
			thanks: {
				"thank-you-message": "Thank you for joining",
				"congrats": "Congrats!",
				"info-line1": "Your registration has been taken into account",
				"info-line2": "We will get back to you shortly!",
				"go-dashboard": "Go to the dashboard",
			},
			cancel: {
				button: "Cancel onboarding",
				message: "You are about to cancel your application. Do you confirm?",
			},
		},
	},
	"basics": {
		"back": "Back",
		"cancel": "Cancel",
		"clear": "Clear",
		"close": "Close",
		"confirm": "Confirm",
		"continue": "Continue",
		"create": "Create",
		"delete": "Delete",
		"details": "Details",
		"download": "Download",
		"edit": "Edit",
		"export": "Export",
		"fill": "Fill",
		"generate": "Generate",
		"import": "Import",
		"miscellaneous": "Miscellaneous",
		"operation-succeeded": "Great job! Operation succeeded.",
		"other": "Other",
		"others": "Others",
		"preview": "Preview",
		"refresh": "Refresh",
		"save": "Save",
		"sign": "Sign",
		"status": "Status",
		"submit": "Submit",
		"upload": "Upload",
		"view": "View",
		"warning": "Warning",
	},
	"utils": {
		formatAddress: {
			"missing-stay-info": "Some stay information is missing",
		},
	},
	"errors": {
		default: "An error occured, try reloading the page.",
	},
	"room-types": {
		[RoomTypeName.STANDARD]: "Standard room",
		[RoomTypeName.PREMIUM]: "Premium room",
		[RoomTypeName.COUPLE]: "Couple room",
	},
};
